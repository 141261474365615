import React from 'react';
import styled from 'styled-components';

const Tags = ({children}): JSX.Element =>(
	<TagsContainer>
		{children}
	</TagsContainer>
);

export default Tags;

const TagsContainer = styled.div`
  font-family: Monaco, monospace;
  font-size: var(--fontSize-16);
  font-weight: 500;
  //TODO: move font definition
  //change to tag-specific colors later

  ul {
    list-style: none;
    margin: 0;
    padding:0;
  }

  li {
    display: inline-block;
    margin: 0;
    padding:0 24px 8px 0;

    a {
      color: rgba(0,0,0,0.4);
      text-decoration: none;
      white-space:no-wrap;

      &:hover {
        color: rgba(0,0,0,1);
      }
    }
  }
`;
